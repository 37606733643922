import * as React from "react";
import { styled } from "@mui/material/styles";
import { SwipeableDrawer, Box, IconButton } from "@mui/material";
import Menu from "./Menu";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import { CloseIcon } from "../../components";

const Drawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paperAnchorLeft": {
    width: "90vw", // Default width
    [theme.breakpoints.up("sm")]: {
      width: "40vw", // Width for screens greater than 600px
    },
  },
}));

export default function MobileDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <Drawer
      anchor="left"
      open={menuOpen}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
      sx={{ zIndex: 2000 }}
    >
      <Box sx={{ p: 1, mb: 4, zIndex: 12 }}>
        <IconButton
          color="inherit"
          aria-label="close drawer"
          onClick={handleDrawerClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Menu />
    </Drawer>
  );
}
