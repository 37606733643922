import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { FilterModeler } from "../../../lib/utils/FilterModeler";
import {
  CustomerAutocompleteFilter,
  CheckAutocompleteFilter,
  DealershipAutocompleteFilter,
} from "../../../components/filters";

export default function FilterRow(props: any) {
  return (
    <Grid sx={{ mb: 3 }} spacing={2} container>
      <Grid xs={12} md={3}>
        <DealershipAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          sx={{ mr: { md: 3, xs: "unset" } }}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "dealership_id",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "dealership_id",
                  operatorValue: "equals",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <CustomerAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          sx={{ mr: { md: 3, xs: "unset" } }}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "customer_name",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "customer_name",
                  operatorValue: "contains",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>

      <Grid xs={12} md={3}>
        <CheckAutocompleteFilter
          setFilterModel={props.setFilterModel}
          filterModel={props.filterModel}
          sx={{ mr: { md: 3, xs: "unset" } }}
          onChange={(event: any, value: any) => {
            let newFilterModel = FilterModeler.removeFilter(
              "check_number",
              props.filterModel,
            );
            if (value) {
              newFilterModel = FilterModeler.addFilter(
                {
                  columnField: "check_number",
                  operatorValue: "startsWith",
                  value: value,
                },
                newFilterModel,
              );
            }
            props.setFilterModel(newFilterModel);
          }}
        />
      </Grid>
    </Grid>
  );
}
