import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { authSelectors, useDispatch, useSelector } from "../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { adminActions } from "../../state";
import { RootStyled } from "./UserPage.styles";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ProfilePage = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(authSelectors.userInfo);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    setInitialValues(userInfo);
  }, [userInfo]);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(adminActions.updateProfile(values));
    setSubmitting(false);
  };

  return (
    <RootStyled>
      <Container maxWidth="lg">
        <Card>
          <CardContent sx={{ pt: 6 }}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
              }) => (
                <Form>
                  <Grid spacing={2} container>
                    <Grid xs={12} md={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="last_name"
                        label="Last Name"
                        required
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        type="email"
                        name="email"
                        label="Email address"
                        disabled={true}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Container>
    </RootStyled>
  );
};

export default ProfilePage;
