import React, { useEffect } from "react";
import logo from "../../assets/img/g.jpg";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { RootStyle, LeftPanel, RightPanel } from "./AuthLayout.styles";
import { useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation } from "../../lib";
import { Backdrop, CircularProgress } from "@mui/material";

export const AuthLayout = React.memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const loading = useSelector(uiSelectors.loading);
  useEffect(() => {
    if (isLoggedIn === true) {
      Navigation.go("/admin/jobs");
    }
  }, [isLoggedIn]);
  return (
    <RootStyle>
      <LeftPanel>
        <img
          alt="Company logo"
          src={logo}
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "cover" }}
        />
      </LeftPanel>
      <RightPanel>
        <Outlet />
      </RightPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
