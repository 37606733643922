import React from "react";
import { Grid, Typography, Box } from "@mui/material";

export function FilterCard(props: {
  label: string;
  amount: string;
  onClick: () => void;
  color: string;
  active: string;
}) {
  return (
    <Grid
      xs={true}
      direction="row"
      container
      onClick={props.onClick}
      item
      justifyContent={"space-between"}
      sx={{
        border:
          props.active === props.label
            ? "3px solid #056839"
            : "1px solid #e0e0e0",
        pb: 1,
        cursor: "pointer",
        width: "100%",
        borderLeft: `6px solid ${props.color} `,
        borderRadius: "4px",
      }}
    >
      <Grid
        item
        container
        alignItems={"flex-start"}
        flexDirection={"column"}
        sx={{
          pt: 2,
          pl: 2,
        }}
      >
        <Typography
          variant={"h4"}
          sx={{
            fontFamily: "Noto Sans Display, sans-serif",
            fontSize: 24,
            alignSelf: "flex-start",
            mb: "4px",
          }}
        >
          {props.amount}
        </Typography>
        <Typography
          sx={{ fontFamily: "Noto Sans Display, sans-serif", fontSize: 12 }}
        >
          {props.label}
        </Typography>
      </Grid>
    </Grid>
  );
}
