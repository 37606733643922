import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  // backgroundImage: `url(${illustration})`,
  // backgroundRepeat: "no-repeat",
  // backgroundPosition: "bottom center",
  // backgroundSize: "100%",
  display: "flex",
  height: "100vh",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  padding: 0,
  // backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down("sm")]: {
    height: "25vh",
    width: "100%",
    padding: 0,
  },
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    maxWidth: "40vw",
    width: "100%",
    display: "flex",
    padding: 0,
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  marginBottom: 100,
  [theme.breakpoints.up("xs")]: {
    height: "auto",
    width: "100%",
  },
}));
