import React from "react";
import { Alert, Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import MiniDrawer from "./MiniDrawer";
import PermanentDrawer from "./PermanentDrawer";
import MobileDrawer from "./MobileDrawer";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { COLLAPSIBLE_DRAWER, __PROD__ } from "../../config";
import { MainContentStyled } from "./AdminLayout.styles";
import { useMobile } from "../../themes";
import MobileTopBar from "./MobileTopBar";
import TopBar from "./TopBar";

export const AdminLayout = React.memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const isMobile = useMobile("(max-width:1023px)");

  return (
    <>
      <CssBaseline />
      {isMobile ? (
        <>
          <MobileTopBar />
          <MobileDrawer />
        </>
      ) : COLLAPSIBLE_DRAWER ? (
        <MiniDrawer />
      ) : (
        <PermanentDrawer />
      )}
      {/* Figure out if the ml is always correct feels hacky */}
      <MainContentStyled
        sx={{
          ml: isMobile ? 0 : 36,
          mt: isMobile ? 4 : 0,
          mr: isMobile ? 0 : 6,
          p: isMobile ? 2 : 3,
        }}
      >
        {!__PROD__ && (
          <Alert
            severity="error"
            style={{
              background: "#bc1d1d",
              color: "white",
              boxShadow: "0px 5px 10px 1px #cc434396",
              marginBottom: 10,
            }}
          >
            THIS STAGING SITE IS FOR TESTING PURPOSES ONLY. DO NOT USE THIS SITE
            FOR LIVE DATA ENTRY.
          </Alert>
        )}
        <TopBar />

        <Outlet />
      </MainContentStyled>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
});
