import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography, IconButton, PaperProps } from "@mui/material";
import { useDispatch, uiActions } from "../../state";
import { drawerWidth } from "./MiniDrawer";
import { useOutlet } from "react-router-dom";
import logo from "../../assets/img/alpinelogo.png";
import MenuIcon from "@mui/icons-material/Menu";

interface AppBarProps extends PaperProps {
  open?: boolean;
}

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MobileTopBar() {
  const dispatch = useDispatch();
  const outlet = useOutlet();
  const pageTitle =
    outlet?.props.children.props.title ??
    outlet?.props.children.props.children?.props.title;
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <>
      <PaperBar
        elevation={1}
        sx={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          borderRadius: 0,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="logo" height={30} />
      </PaperBar>
      <Typography variant="h4" noWrap component="h1" sx={{ ml: 2, mt: 2 }}>
        {pageTitle}
      </Typography>
    </>
  );
}
