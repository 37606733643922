import { useEffect, useMemo, useState } from "react";
import {
  DataGridPro,
  GridComparatorFn,
  GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import AdminPages from "../index";
import { RootStyled } from "./JobsPage.styles";
import { dataActions } from "../../../state/data/actions";
import { dataSelectors } from "../../../state/data/selectors";
import { StatusLabel } from "../../../components/labels/StatusLabel";
import { PaymentStatusLabel } from "../../../components/labels/PaymentStatusLabel";
import currency from "currency.js";
import { MiniDash } from "./MiniDash";
import FilterRow from "./FilterRow";
import BulkUpdate from "./BulkUpdate";
import { Navigation } from "../../../lib";
import { useMobile } from "../../../themes";
import { Box, IconButton, Menu } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
export const JobsPage = () => {
  const dispatch = useDispatch();
  const jobs = useSelector(dataSelectors.jobs);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [dateFilterModel, setDateFilterModel] = useState({});
  const [bottomFilter, setBottomFilter] = useState({ items: [] });
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => {
    let query = "";
    if (filterModel.items.length) {
      filterModel.items.forEach((i: any) => {
        if (!query) {
          query += "?";
        } else {
          query += "&";
        }
        if (i.columnField === "date_range") {
          if (!i.value?.end_date) return;
          query += `start_date=${i.value.start_date}&end_date=${i.value.end_date}`;
          setDateFilterModel({
            startDate: i.value.start_date,
            endDate: i.value.end_date,
          });
        } else query += `${i.columnField}=${i.value}`;
      });
    }
    if (bottomFilter.items.length) {
      bottomFilter.items.forEach((i: any) => {
        if (!query) {
          query += "?";
        } else {
          query += "&";
        }
        if (i.columnField === "date_range") {
          if (!i.value?.end_date) return;
          query += `start_date=${i.value.start_date}&end_date=${i.value.end_date}`;
          setDateFilterModel({
            startDate: i.value.start_date,
            endDate: i.value.end_date,
          });
        } else query += `${i.columnField}=${i.value}`;
      });
    }
    if (filterModel.items.length || bottomFilter.items.length) {
      dispatch(dataActions.getJobs(query));
    } else {
      dispatch(dataActions.getJobs());
    }
  }, [dispatch, filterModel, bottomFilter]);

  const currencyComparator: GridComparatorFn = (v1, v2) => {
    return currency(v1 as number).value - currency(v2 as number).value;
  };

  const columns = useMemo(
    () => [
      { field: "id", hide: true },
      { field: "created_at", hide: true },
      { field: "invoice_number", headerName: "Invoice Number", width: 150 },
      {
        field: "status_id",
        headerName: "Job Status",
        width: 240,
        renderCell: (params) => (
          <StatusLabel status={params?.row?.status?.name} />
        ),
      },
      {
        field: "payment_status_id",
        headerName: "Payment Status",
        width: 170,
        renderCell: (params) => (
          <PaymentStatusLabel status={params?.row?.payment_status?.name} />
        ),
      },
      {
        field: "dealership_id",
        headerName: "Dealership",
        width: 150,
        valueGetter: (params) => {
          return params?.row?.dealership?.dealership_name;
        },
      },
      { field: "customer_name", headerName: "Customer", width: 150 },
      {
        field: "paperwork_received_date",
        headerName: "Received Date",
        width: 150,
      },
      { field: "state", headerName: "State", width: 100 },
      {
        field: "service_id",
        headerName: "Service",
        width: 250,
        valueGetter: (params) => params?.row?.service?.name,
      },
      {
        field: "total",
        headerName: "Total",
        width: 150,
        sortComparator: currencyComparator,
        valueGetter: (params) => {
          return currency(params?.row?.mv_fee)
            .add(params?.row?.alpine_fee)
            .add(params?.row?.sales_tax)
            .format();
        },
      },
      {
        field: "paid",
        headerName: "Paid",
        width: 150,
        sortComparator: currencyComparator,
        valueGetter: (params) => {
          return currency(
            //WARNING if you regenerate models you will need to manually update this
            params?.row?.check_jobsq?.reduce((acc, check) => {
              return acc.add(check.amount);
            }, currency(0)),
          ).format();
        },
      },
      {
        field: "check_number",
        headerName: "Checks",
        width: 150,
        valueGetter: (params) => {
          return params?.row?.checks?.map((check) => {
            return check?.check_number;
          });
        },
      },

      {
        field: "created_by_user_id",
        headerName: "Created By",
        width: 150,
        valueGetter: (params) =>
          params?.row?.created_by_user?.first_name +
          " " +
          params?.row?.created_by_user?.last_name,
      },
      {
        field: "last_edited_by_user_id",
        headerName: "Last Edited By",
        width: 150,
        valueGetter: (params) =>
          params?.row?.last_edited_by_user?.first_name +
          " " +
          params?.row?.last_edited_by_user?.last_name,
      },
    ],
    [],
  );
  return (
    <RootStyled>
      <MiniDash setFilterModel={setFilterModel} filterModel={filterModel} />
      {/* TODO: make collapsible for mobile devices */}
      <MobileFilterRow
        setBottomFilter={setBottomFilter}
        bottomFilter={bottomFilter}
      />
      <BulkUpdate
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        data={jobs}
        dateFilterModel={dateFilterModel}
      />
      <DataGridPro
        disableColumnFilter={true}
        onRowClick={(params) => {
          dispatch(dataActions.getJob(params.id));
          Navigation.go(
            AdminPages.editJob.path.replace(":id", params?.id.toString()),
          );
        }}
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
          minHeight: "400px",
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "created_at", sort: "desc" }],
          },
        }}
        rows={jobs}
        columns={columns}
        disableSelectionOnClick={true}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </RootStyled>
  );
};

function MobileFilterRow({ setBottomFilter, bottomFilter }) {
  const isMobile = useMobile("(max-width:1199px)");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (isMobile) {
    return (
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FilterListIcon color="primary" />
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box padding={2}>
            <FilterRow
              setFilterModel={setBottomFilter}
              filterModel={bottomFilter}
            />
          </Box>
        </Menu>
      </Box>
    );
  }
  return (
    <FilterRow setFilterModel={setBottomFilter} filterModel={bottomFilter} />
  );
}
