import { styled } from "@mui/material/styles";
import { ErrorMessage } from "formik";

export const RootStyled = styled(`div`)(({ theme }) => ({
  height: "calc(100vh - 72px)",
  border: "0px",
}));

export const ErrorMessageStyled = styled(ErrorMessage)(({ theme }) => ({
  color: "red",
}));
