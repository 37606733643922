import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { People } from "@mui/icons-material";
import AdminPages from "../../pages/admin";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, ListItemButton } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../themes";
import { uiActions, useDispatch } from "../../state";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import FeedIcon from "@mui/icons-material/Feed";
import ReceiptIcon from "@mui/icons-material/Receipt";
const menuItems = [
  // {
  //   label: "Dashboard",
  //   Icon: Dashboard,
  //   to: AdminPages.dashboard.path,
  // },
  {
    label: "Jobs",
    Icon: FeedIcon,
    to: AdminPages.jobs.path,
  },
  {
    label: "Dealerships",
    Icon: CarRepairIcon,
    to: AdminPages.dealerships.path,
  },
  {
    label: "Users",
    Icon: People,
    to: AdminPages.users.path,
  },
  {
    label: "Makes",
    Icon: DirectionsCarIcon,
    to: AdminPages.makes.path,
  },
  {
    label: "Checks",
    Icon: ReceiptIcon,
    to: AdminPages.checks.path,
  },
];

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile("(max-width:1023px)");
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const itemButtonClick = (to) => {
    if (isMobile) {
      handleDrawerClose();
    }
    navigate(to);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <List>
            {menuItems.map(({ label, Icon, to }) => (
              <ListItemButton
                onClick={() => itemButtonClick(to)}
                key={label}
                selected={location.pathname === to}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
        </Box>
        <Box>
          <List>
            <ProfileMenu />
          </List>
        </Box>
      </Box>
    </>
  );
}
