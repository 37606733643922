import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { authSelectors, uiSelectors, useSelector } from "../../state";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userInfo = useSelector(authSelectors.userInfo);
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <ListItemButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ListItemIcon>
          <Avatar sx={{ width: 24, height: 24, fontSize: 14 }}>
            {userInfo.first_name.charAt(0)}
          </Avatar>
        </ListItemIcon>
        {menuOpen && (
          <ListItemText
            sx={{ fontSize: 14 }}
            primary={`${userInfo.first_name} ${userInfo.last_name}`}
          />
        )}
      </ListItemButton>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: 3000 }}
      >
        <MenuItem
          key={2}
          onClick={handleClose}
          component={Link}
          to="/admin/profile"
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/auth/logout">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
