import React from "react";
import {
  Box,
  //
  Button,
  Typography,
} from "@mui/material";
// Assets
// Local
import { useLocation } from "../../lib";
import { useStyles } from "./Auth.styles";
import { authActions, useAction } from "../../state";
import logo from "../../assets/logo/alpine-logo.png";

export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const classes = useStyles();
    const location = useLocation();
    const recoverPassword = useAction(authActions.recoverPassword);
    const resendEmail = (e) => {
      console.log("e", location.query.email);
      recoverPassword({ email: location.query.email });
    };
    return (
      <Box className={classes.authCard}>
        <Box className={classes.logo} sx={{ mt: { xs: 2, md: 4 } }}>
          <img src={logo} alt="" height={72} />
        </Box>
        <Typography
          sx={{ mt: 1, mb: 1, textAlign: { xs: "center" } }}
          variant="h6"
          className={classes.titleText}
        >
          Forgot Password for {location.query.email || "your E-mail"}
        </Typography>
        <Typography
          sx={{ mt: 1, mb: 1, textAlign: { xs: "center" } }}
          variant="body2"
          className={classes.subtitleText}
        >
          We’ve sent you an email with a link and instructions to reset your
          password.
          <br />
          <br />
          Didn’t receive the email? Check your junk mail or have another one
          sent to you by clicking the button below.
        </Typography>
        <div className={classes.buttonBox + " " + classes.buttonBoxSingle}>
          <Button
            sx={{ mt: 15 }}
            variant="contained"
            type="button"
            onClick={resendEmail}
          >
            RESEND EMAIL
          </Button>
        </div>
      </Box>
    );
  },
);
