import { makeStyles } from "@mui/styles";
// import pattern from "../../assets/img/login_cars_pattern.svg";

export const useStyles: any = makeStyles(
  (theme) => ({
    authCard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      marginBottom: 32,
    },
    titleText: {
      font: "Roboto",
      color: "#056839",
      fontFamily: "Roboto",
      fontSize: "38px",
      letterSpacing: "0.28px",
      lineHeight: "44px",
    },
    subtitleText: {
      //color: theme.palette.primary.main,
      font: "Roboto",
      height: "24px",
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Roboto",
      fontSize: "16px",
      letterSpacing: " 0.15px",
      lineHeight: "24px",
      alignSelf: "flex-center",
    },
    formTitle: {
      font: "Roboto",
      height: 44,
      color: "#056839",
      fontFamily: "Roboto",
      fontSize: " 38px",
      letterSpacing: "0.28px",
      lineHeight: "44px",
      // alignSelf: "flex-start",
    },
    formSubtitle: {
      font: "Roboto",
      height: "24px",
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Roboto",
      fontSize: "16px",
    },
    errorText: {
      //color: theme.palette.error.main,
    },
    form: {
      paddingRight: 8,
      paddingLeft: 8,
      "& .MuiFormControl-root": {
        paddingBottom: "2vh",
        minHeight: "2.2vh",
      },
    },
    flexJustifySpace: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      // [theme.breakpoints.down("md")]: {
      //   flexDirection: "column",
      // },
    },
    buttonBox: {
      marginTop: "1.7vh",

      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      // [theme.breakpoints.down("md")]: {
      //   flexDirection: "column",
      // },
      "& button": {
        // backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        color: "white",
        height: 48,
        textTransform: "uppercase",
        padding: "0 1.6vmax",
        backgroundColor: "#056839",
      },
    },
    buttonBoxSingle: {
      justifyContent: "center",
    },
    forgotPassBox: {
      marginTop: 16,
      height: "20px",
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "0.25px",
      lineHeight: "20px",
      textAlign: "center",
      textDecoration: "none",
      "& a": { textDecoration: "none" },
    },
    textFieldInput: { height: 76 },
  }),
  {
    classNamePrefix: "AuthPage",
  },
);
