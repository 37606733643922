import { useEffect, useState } from "react";
import qs from "query-string";
import { useDispatch } from "react-redux";
import { Box, Button, Tooltip, Typography } from "@mui/material/";
import ConfirmDialog from "./ConfirmDialog";
import { dataActions, PaymentStatusType } from "../../../state";
import { formatDate } from "../../../lib";

export default function BulkUpdate({
  selectionModel,
  setSelectionModel,
  dateFilterModel,
  data,
}) {
  const dispatch = useDispatch();
  const [allDealershipsIsEqual, setAllDealershipsIsEqual] = useState(false);
  const [hasPaymentAlready, setHasPaymentAlready] = useState(false);
  const [selectedRows, setSelectedRows] = useState([{ dealership_id: 0 }]);
  const [modal, setModal] = useState(false);

  const handleModalClose = (data) => {
    setModal(false);
  };

  function testAllDealershipsIsEqual(rows) {
    if (!rows.length) return false;
    return !!rows.reduce((a, b) =>
      a.dealership?.dealership_name === b.dealership?.dealership_name ? a : NaN,
    );
  }

  function testIfHasPayment(rows) {
    if (!rows.length) return false;
    const hasPayments = rows.filter(
      (row) =>
        row.payment_status_id === PaymentStatusType.PARTIALLY_PAID ||
        row.payment_status_id === PaymentStatusType.PAID,
    );
    return !!hasPayments.length;
  }

  useEffect(() => {
    const selected = data.filter((filtered) =>
      selectionModel.includes(filtered.id),
    );
    setSelectedRows(selected);
    setAllDealershipsIsEqual(testAllDealershipsIsEqual(selected));
    setHasPaymentAlready(testIfHasPayment(selected));
  }, [data, selectionModel]);

  async function handleSubmit() {
    if (selectionModel.length) {
      const query = qs.stringify({
        print_id: selectionModel,
      });
      const startDate = formatDate(dateFilterModel.startDate);
      const endDate = formatDate(dateFilterModel.endDate);
      dispatch(dataActions.printJobsBulk(query, startDate, endDate));
    }

    setSelectionModel([]);
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        marginBottom: "16px",
        gap: 2,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Typography variant="body2">
          {`${selectionModel.length} selected`}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          alignItems: "center",
          gap: 1,
        }}
      >
        <Tooltip
          title={
            !allDealershipsIsEqual
              ? hasPaymentAlready
                ? "Cannot update jobs across multiple dealerships. Cannot bulk update jobs with payments applied."
                : "Cannot update jobs across multiple dealerships."
              : hasPaymentAlready
              ? "Cannot bulk update jobs with payments applied."
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              onClick={() => setModal(true)}
              disabled={!allDealershipsIsEqual || hasPaymentAlready}
            >
              Update payment status
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !allDealershipsIsEqual
              ? "Select from the same dealership to print a report."
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!allDealershipsIsEqual}
            >
              Print dealership report
            </Button>
          </span>
        </Tooltip>
      </Box>
      {modal && (
        <ConfirmDialog
          modal={modal}
          onClose={handleModalClose}
          selectedRows={selectedRows}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          dealership_id={selectedRows[0]?.dealership_id}
        />
      )}
    </Box>
  );
}
