import React from "react";
import {
  //
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
//Assets
import logo from "../../assets/logo/alpine-logo.png";
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import AuthPages from ".";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const ConfirmAccountPage = React.memo(
  /**
   *
   */
  function ConfirmAccountPage() {
    const classes = useStyles();
    const confirmAccount = useAction(authActions.confirmAccount);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        // TODO: Validate passwords. See mailpake/prime client-app ResetPasswordPage.jsx
        await confirmAccount({
          newPassword: values.password1,
          email: location.query.email,
          token: location.query.token,
        });
        Navigation.replace(AuthPages.login);
      },
    });

    return (
      <Box className={classes.authCard}>
        <Box className={classes.logo} sx={{ mt: { xs: 2, md: 4 } }}>
          <img src={logo} alt="" height={72} />
        </Box>
        <Typography variant="h5" className={classes.titleText}>
          Confirm Account
        </Typography>
        <Typography
          variant="body2"
          className={classes.subtitleText}
          sx={{ mt: 1, mb: 2 }}
        >
          Enter your new password here.
        </Typography>
        <Form form={frm} className={classes.form}>
          <TextField
            fullWidth
            id="password1"
            name="password1"
            label="New password"
            type="password"
            autoComplete="new-password"
            value={frm.values.password1}
            onChange={frm.handleChange}
            error={frm.touched.password1 && Boolean(frm.errors.password1)}
            helperText={frm.touched.password1 && frm.errors.password1}
            className={classes.textFieldInput}
          />
          <TextField
            fullWidth
            id="password2"
            name="password2"
            label="Repeat new password"
            type="password"
            autoComplete="reenter-new-password"
            value={frm.values.password2}
            onChange={frm.handleChange}
            error={frm.touched.password2 && Boolean(frm.errors.password2)}
            helperText={frm.touched.password2 && frm.errors.password2}
            className={classes.textFieldInput}
          />
          <div className={classes.buttonBox}>
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
            >
              Signup
            </Button>
          </div>
        </Form>
      </Box>
    );
  },
);
